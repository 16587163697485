<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <div v-if="error">
            <mdb-alert color="danger">{{error}}</mdb-alert>
          </div>
          <mdb-container fluid>
            <mdb-row>
              <mdb-col>
                <h1>{{$t('holasun.departures_table_view')}}</h1>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <mdb-select
                  color="primary"
                  v-model="searchparameters.flightnum"
                  :search=true
                  :searchPlaceholder="$t('searchengine.search')"
                  :label="$t('syncwithdarwin.flightnum')"
                  :visibleOptions=10
                />
              </mdb-col>
              <mdb-col>
                <mdb-select
                  color="primary"
                  v-model="searchparameters.departuregateway"
                  :search=true
                  :searchPlaceholder="$t('searchengine.search')"
                  :label="$t('flightscalendar.origin')"
                  :visibleOptions=10
                />
              </mdb-col>
              <mdb-col>
                <mdb-select
                  color="primary"
                  v-model="searchparameters.arrivalgateway"
                  :search=true
                  :searchPlaceholder="$t('searchengine.search')"
                  :label="$t('flightscalendar.destination')"
                  :visibleOptions=10
                />
              </mdb-col>
              <mdb-col>
                <mdb-date-picker-2
                  :label="$t('alerts.startat')"
                  :title="$t('alerts.startat')"
                  v-model="searchparameters.mindeparturedate" />
              </mdb-col>
              <mdb-col>
                <mdb-date-picker-2
                  :label="$t('alerts.endat')"
                  :title="$t('alerts.endat')"
                  v-model="searchparameters.maxdeparturedate" />
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col class="text-right">
                <mdb-btn outline="elegant" v-on:click="loadtabledata()">{{$t('searchengine.search')}}</mdb-btn>
                <hr />
              </mdb-col>
            </mdb-row>
            <mdb-row v-if="isloading">
              <mdb-col>
                <mdb-spinner />
              </mdb-col>
            </mdb-row>
            <mdb-row v-else>
              <mdb-col xl="12" md="12">
                <mdb-tbl>
                  <mdb-tbl-head>
                    <tr>
                      <th><mdb-icon icon="hashtag" size="2x" /></th>
                      <th><mdb-icon icon="calendar" size="2x" /></th>
                      <th><mdb-icon icon="plane-departure" size="2x" /></th>
                      <th><mdb-icon icon="plane-arrival" size="2x" /></th>
                      <th><mdb-icon icon="male" size="2x" /></th>
                      <th><mdb-icon icon="female" size="2x" /></th>
                      <th><mdb-icon icon="child" size="2x" /></th>
                      <th><mdb-icon icon="baby" size="2x" /></th>
                      <th class="bold">Total</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body>
                    <tr v-for="(flight,index) in searchresults" v-bind:key="index">
                      <td><a class="underline" v-on:click="gotoFlightDetails(flight.flightid)">{{flight.flightnum}}</a></td>
                      <td>{{flight.departuredate.split('T')[0]}}</td>
                      <td>{{flight.departuregateway}}</td>
                      <td>{{flight.arrivalgateway}}</td>
                      <td>{{flight.nbmen}}</td>
                      <td>{{flight.nbwomen}}</td>
                      <td>{{flight.nbchild}}</td>
                      <td>{{flight.nbinfant}}</td>
                      <td class="bold">{{flight.nbmen + flight.nbwomen + flight.nbchild}} + {{ flight.nbinfant }}</td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbSpinner,
  mdbAlert,
  mdbSelect,
  mdbDatePicker2,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbIcon,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'holasunstats',
  data() {
    return {
      error: null,
      success: null,
      isloading: true,
      searchparameters: {
        flightnum: null,
        mindeparturedate: null,
        maxdeparturedate: null,
        departuregateway: null,
        arrivalgateway: null,
      },
      searchresults: [],
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbSpinner,
    mdbAlert,
    mdbSelect,
    mdbDatePicker2,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
  },
  mounted() {
    const [minb, mine] = new Date().toISOString().split('T');
    const [maxb, maxe] = new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T');

    this.searchparameters.mindeparturedate = minb;
    this.searchparameters.maxdeparturedate = maxb;
    const self = this;
    this.loadAvailableOptions().then(() => {
      self.loadtabledata();
    });
  },
  methods: {
    loadtabledata() {
      this.isloading = true;
      const self = this;
      const api = new Apicall();
      const params = JSON.parse(JSON.stringify(this.searchparameters));
      let flightnum = null;
      for (let i = 0; i < this.searchparameters.flightnum.length; i += 1) {
        if (this.searchparameters.flightnum[i].selected) {
          flightnum = this.searchparameters.flightnum[i].value;
        }
      }
      params.flightnum = flightnum;

      let depgateway = null;
      for (let i = 0; i < this.searchparameters.departuregateway.length; i += 1) {
        if (this.searchparameters.departuregateway[i].selected) {
          depgateway = this.searchparameters.departuregateway[i].value;
        }
      }
      params.departuregateway = depgateway;

      let arrgateway = null;
      for (let i = 0; i < this.searchparameters.arrivalgateway.length; i += 1) {
        if (this.searchparameters.arrivalgateway[i].selected) {
          arrgateway = this.searchparameters.arrivalgateway[i].value;
        }
      }
      params.arrivalgateway = arrgateway;

      api.call('POST', 'api/holasun/table-view', params).then((response) => {
        self.searchresults = response.data;
        self.isloading = false;
      });
    },
    async loadAvailableOptions() {
      this.isloading = true;
      const self = this;
      const api = new Apicall();
      const results = await api.call('GET', 'api/holasun/table-view-available-options');
      this.searchparameters.flightnum = results.data.flightnums;
      this.searchparameters.departuregateway = JSON.parse(JSON.stringify(results.data.gateways));
      this.searchparameters.arrivalgateway = JSON.parse(JSON.stringify(results.data.gateways));
      return true;
    },
    gotoFlightDetails(flightid) {
      this.$router.push({ name: 'HolasunFlightDetails', params: { id: flightid } });
    },
  },
};
</script>

<style>
  a.underline{text-decoration: underline!important;}
  td.bold, th.bold{font-weight: bold;}
</style>
